<template>
  <div class="page-container">
    <el-row>
      <el-col :span="4" class="tabs">
        <div :class="tabIndex === 0 ? 'active' : ''" @click="changeTabs(0)">
          <span>已合作客户（{{ total.cooperation }}）</span>
        </div>
        <div :class="tabIndex === 1 ? 'active' : ''" @click="changeTabs(1)">
          <span>未合作客户（{{ total.unCooperation }}）</span>
        </div>
      </el-col>
      <el-col :span="14">
        <el-form ref="form" :model="searchForm">
          <el-form-item>
            <el-col :span="8">
              <el-input
                v-model="searchForm.customerOrTerminalName"
                placeholder="请输入客户名称"
              ></el-input>
            </el-col>
            <el-col v-if="tabIndex === 0" :span="8" :offset="1">
              <el-input
                v-model="searchForm.supplyFullName"
                placeholder="请输入对接人姓名"
              ></el-input>
            </el-col>
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="6" style="text-align: right">
        <el-button type="primary" @click="hanndleSearch">查询</el-button>
        <el-button @click="hanndleReset">重置</el-button>
        <el-button>导出</el-button>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="4" class="tree-container">
        <el-tree
          ref="tree"
          :data="treeData"
          show-checkbox
          default-expand-all
          :props="defaultProps"
          node-key="statisticianType"
          @check="handleCheckChange"
        >
        </el-tree>
      </el-col>
      <el-col :span="20">
        <div v-if="chartOrMap === true">
          <div class="controls">
            <el-button @click="backChina">返回全国地图</el-button>
          </div>
          <div class="echarts" id="echarts"></div>
        </div>

        <div v-if="chartOrMap === false" class="map">
          <div class="controls">
            <el-button @click="backChina">返回全国地图</el-button>
          </div>
          <el-amap
            class="amap-box"
            :vid="'amap'"
            :plugin="amap.plugin"
            :center="amap.center"
            :zoom="amap.zoom"
          >
            <el-amap-info-window
              v-if="amapInfoWindow.visible"
              :position="amapInfoWindow.position"
              :visible="amapInfoWindow.visible"
              :content="amapInfoWindow.content"
              :offset="amapInfoWindow.offset"
            >
              <div class="input-card content-window-card">
                <!-- <div><img style="float:left;" src="https://webapi.amap.com/images/autonavi.png"/></div> -->
                <div style="padding: 7px 0px 0px 0px">
                  <h4>{{ amapInfoWindow.name }}</h4>
                  <p class="input-item">地址: {{ amapInfoWindow.address }}</p>
                  <p class="input-item">联系人:{{ amapInfoWindow.linker }}</p>
                  <p class="input-item">电话:{{ amapInfoWindow.mobile }}</p>
                  <p class="input-item">
                    对接人:{{ amapInfoWindow.supplyFullName }}
                  </p>
                </div>
              </div>
            </el-amap-info-window>
            <el-amap-marker
              v-for="(marker, index) in markers"
              :position="marker.position"
              :key="index"
              :events="marker.events"
            ></el-amap-marker>
          </el-amap>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import echarts from 'echarts';
import { lazyAMapApiLoaderInstance } from 'vue-amap';
import remoteload from './lib/remoteload';
import request from '../../../../utils/request';
// eslint-disable-next-line max-len
const provinces = [
  'shanghai',
  'hebei',
  'shanxi',
  'neimenggu',
  'liaoning',
  'jilin',
  'heilongjiang',
  'jiangsu',
  'zhejiang',
  'anhui',
  'fujian',
  'jiangxi',
  'shandong',
  'henan',
  'hubei',
  'hunan',
  'guangdong',
  'guangxi',
  'hainan',
  'sichuan',
  'guizhou',
  'yunnan',
  'xizang',
  'shanxi1',
  'gansu',
  'qinghai',
  'ningxia',
  'xinjiang',
  'beijing',
  'tianjin',
  'chongqing',
  'xianggang',
  'aomen',
];
const provincesText = [
  '上海',
  '河北',
  '山西',
  '内蒙古',
  '辽宁',
  '吉林',
  '黑龙江',
  '江苏',
  '浙江',
  '安徽',
  '福建',
  '江西',
  '山东',
  '河南',
  '湖北',
  '湖南',
  '广东',
  '广西',
  '海南',
  '四川',
  '贵州',
  '云南',
  '西藏',
  '陕西',
  '甘肃',
  '青海',
  '宁夏',
  '新疆',
  '北京',
  '天津',
  '重庆',
  '香港',
  '澳门',
];
export default {
  data() {
    return {
      chartOrMap: true,
      myChart: null,
      china: require('./map/china.json'),
      mapName: 'china',
      mapJson: {},
      tabIndex: 0,
      total: {
        cooperation: 0,
        unCooperation: 0,
      },
      searchForm: {
        customerOrTerminalName: '', // 客户或终端名称
        supplyFullName: '', // 对接人
        amapDistrictCode: '', // 上级地图code
        statisticianTypeList: [], // 选中类型编码
      },
      treeData: [], // 树
      defaultProps: {
        children: 'children',
        label: 'statisticianName',
      },
      amapType: 'province', // 当前地图类型 省:province 市:city 区:area
      mapData: [],
      amap: {
        center: [104.06632, 30.572903],
        zoom: 12,
        plugin: [
          {
            zoomToAccuracy: true,
          },
        ],
      },
      AMapUI: null,
      AMap: null,
      mapCityName: '全国',
      placeSearch: null,
      dragStatus: false,
      markers: [],
      infoWindows: [],
      amapInfoWindow: {
        visible: false,
      },
    };
  },
  created() {
    this.getTotal();
    this.cooperationTree();
  },
  mounted() {
    this.myChart = echarts.init(document.getElementById('echarts'));
    this.mapJson = this.china;
  },
  methods: {
    // Tab切换
    changeTabs(e) {
      this.tabIndex = e;
      if (e === 0) {
        this.cooperationTree();
        this.backChina();
      } else {
        this.unCooperationTree();
        this.backChina();
      }
    },

    // 获取总数
    async getTotal() {
      await request
        .get('/mdm/mdmAmapStatisticsController/statisticalTotal')
        .then((res) => {
          this.total.cooperation = res.result.cooperationTypeCount;
          this.total.unCooperation = res.result.unCooperationTypeCount;
        });
    },

    // 递归
    resetData(arr) {
      const newArr = arr;
      newArr.forEach((e, index) => {
        newArr[index].statisticianName = `${e.statisticianName}(${
          e.statisticianCount || 0
        })`;
        if (e.children && e.children.length > 0) {
          this.resetData(e.children);
        }
      });
      return newArr;
    },

    // 获取已合作树
    async cooperationTree() {
      await request
        .get('/mdm/mdmAmapStatisticsController/statisticalType')
        .then((res) => {
          this.treeData = this.resetData(res.result);
          // 默认全选
          this.$nextTick(() => {
            this.$refs.tree.setCheckedNodes(this.treeData);
            setTimeout(() => {
              this.handleCheckChange();
            }, 1000);
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 获取未合作树
    async unCooperationTree() {
      await request
        .get('/mdm/mdmAmapStatisticsController/unCooperationStatisticalType')
        .then((res) => {
          this.treeData = this.resetData(res.result);
          // 默认全选
          this.$nextTick(() => {
            this.$refs.tree.setCheckedNodes(this.treeData);
            setTimeout(() => {
              this.handleCheckChange();
            }, 1000);
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 图表
    initEcharts(name, mapdata, data) {
      this.myChart.clear();
      echarts.registerMap(name, mapdata);
      const option = {
        tooltip: {},
        legend: {
          orient: 'vertical',
          left: 'left',
          data: [''],
        },
        selectedMode: 'single',
        series: [
          {
            name: '',
            type: 'map',
            mapType: name,
            itemStyle: {
              normal: {
                borderColor: 'rgba(0, 0, 0, 0.2)',
              },
            },
            showLegendSymbol: true,
            label: {
              normal: {
                show: true,
              },
              emphasis: {
                show: true,
              },
            },
            data,
          },
        ],
        animationDuration: 1000,
        animationEasing: 'cubicOut',
        // 数据更新动画的时长。
        animationDurationUpdate: 1000,
      };
      this.myChart.off('click');
      this.myChart.setOption(option);

      this.myChart.on('click', (params) => {
        if (!params.data) {
          return false;
        }
        this.mapName = params.data.py;
        this.searchForm.amapDistrictCode = params.data.code;
        // 处理省模块
        if (params.data.type === 'province') {
          this.amapType = 'city';
          const index = provincesText.indexOf(params.name);
          if (index > -1) {
            const provinceName = provinces[index];
            // eslint-disable-next-line import/no-dynamic-require
            const mapJson = require(`./map/json/province/${provinceName}.json`);
            this.mapJson = mapJson;
            this.hanndleSearch();
          }
        }
        // 处理市模块
        if (params.data.type === 'city') {
          this.amapType = 'area';
          const { code } = params.data;
          if (code) {
            // eslint-disable-next-line import/no-dynamic-require
            const mapJson = require(`./map/json/citys/${code}.json`);
            this.mapJson = mapJson;
            this.hanndleSearch();
          }
        }
        if (params.data.type === 'area') {
          this.chartOrMap = false;
          this.getMarker();
          // this.initAMap();
        }
      });

      window.addEventListener('resize', () => {
        this.myChart.resize();
      });
    },

    // 返回全国地图
    backChina() {
      this.chartOrMap = true;
      this.amapType = 'province';
      this.mapName = 'china';
      this.mapJson = this.china;
      this.searchForm.amapDistrictCode = '';
      this.$nextTick(() => {
        this.myChart = echarts.init(document.getElementById('echarts'));
        this.getCooperationData();
      });
    },

    // 选中树回调
    async handleCheckChange(data, checked, indeterminate) {
      // console.log(data, checked, indeterminate);
      let checkedKeys = this.$refs.tree.getCheckedKeys();
      const childrenCheckedKdys = checkedKeys.filter((item) => {
        if (item !== 'terminal' && item !== 'customer') {
          return item;
        }
        return false;
      });
      checkedKeys = null;
      this.searchForm.statisticianTypeList = childrenCheckedKdys;
      if (this.chartOrMap === true) {
        this.getCooperationData();
      } else {
        this.getMarker();
      }
    },

    // 查询合作客户或终端区域统计
    async getCooperationData() {
      let path = '';
      if (this.tabIndex === 0) {
        // 已合作
        path = '/mdm/mdmAmapStatisticsController/statisticalDistrict';
      } else {
        // 未合作
        path = '/mdm/mdmAmapStatisticsController/unCooperationStatisticalDistrict';
      }
      await request
        .post(path, this.searchForm)
        .then((res) => {
          const arr = [];
          res.result.forEach((item) => {
            let name = '';
            if (this.amapType === 'province') {
              name = item.amapName.replace('省', '');
            } else {
              name = item.amapName;
            }
            arr.push({
              name,
              py: item.amapPinyin || '',
              type: this.amapType,
              value: item.amapStatistician,
              code: item.amapCode,
              itemStyle: {
                areaColor: '#6552D5',
              },
            });
          });
          this.mapData = arr;
          this.$nextTick(() => {
            this.initEcharts(this.mapName, this.mapJson, this.mapData);
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 查询未合作客户或终端区域统计
    async getUnCooperationData() {
      await request
        .post(
          '/mdm/mdmAmapStatisticsController/unCooperationStatisticalType',
          this.searchForm,
        )
        .then((res) => {
          console.log(res);
          const arr = [];
          let type = '';
          if (this.amapType === '') {
            this.amapType = 'province';
            type = 'province';
          } else if (this.amapType === 'province') {
            this.amapType = 'city';
            type = 'city';
          } else {
            this.amapType = 'area';
            type = 'area';
          }
          res.result.forEach((item) => {
            let name = '';
            if (type === 'province') {
              name = item.amapName.replace('省', '');
            } else {
              name = item.amapName;
            }
            arr.push({
              name,
              py: item.amapPinyin || '',
              type,
              value: item.amapStatistician,
              code: item.amapCode,
              itemStyle: {
                areaColor: '#6552D5',
              },
            });
          });
          this.mapData = arr;
          this.$nextTick(() => {
            this.initEcharts('china', this.mapJson, this.mapData);
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 查询区县统计
    async getAreaData() {
      let path = '';
      if (this.tabIndex === 0) {
        // 已合作
        path = '/mdm/mdmAmapStatisticsController/statisticalDistrict';
      } else {
        // 未合作
        path = '/mdm/mdmAmapStatisticsController/statisticalDistrict';
      }
      await request
        .post(path, this.searchForm)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 获取地图标记数据
    async getMarker() {
      this.markers = []; // 重置标记
      this.amapInfoWindow.visible = false; // 重置信息窗体
      let path = '';
      if (this.tabIndex === 0) {
        // 已合作
        path = '/mdm/mdmAmapStatisticsController/district';
      } else {
        // 未合作
        path = '/mdm/mdmAmapStatisticsController/unCooperationDistrict';
      }
      await request
        .post(path, this.searchForm)
        .then((res) => {
          if (res.result && res.result.length > 0) {
            const arr = [];
            const infoWindows = [];
            const that = this;
            res.result.forEach((item, index) => {
              // 取第一个点作为地图中心点
              if (index === 0) {
                this.amap.center = [item.longitude, item.latitude];
              }
              if (item.longitude) {
                arr.push({
                  name: item.statisticianName,
                  address: item.statisticianAddress,
                  linker: item.statisticianContactName,
                  mobile: item.statisticianContactPhone,
                  position: [item.longitude, item.latitude],
                  events: {
                    click() {
                      that.amapInfoWindow = {
                        visible: false,
                        position: [item.longitude, item.latitude],
                        content: item.statisticianAddress,
                        offset: [0, -15],
                        name: item.statisticianName,
                        address: item.statisticianAddress,
                        linker: item.statisticianContactName,
                        mobile: item.statisticianContactPhone,
                        supplyFullName: item.statisticianFullName,
                      };
                      that.$nextTick(() => {
                        that.amapInfoWindow.visible = true; // 点击点坐标，出现信息窗体
                      });
                    },
                  },
                });
                //       infoWindows.push({
                //   position: [item.longitude, item.latitude],
                //   offset: [115, 55], // 窗体偏移
                //   showShadow: false,
                //   visible: false, // 初始是否显示
                //   address: item.statisticianAddress
                // })
              }
            });
            this.markers = arr;
            this.infoWindows = infoWindows;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 表单查询
    hanndleSearch() {
      this.getCooperationData();
    },

    // 表单重置
    hanndleReset() {
      this.searchForm.customerOrTerminalName = '';
      this.searchForm.supplyFullName = '';
      this.hanndleSearch();
    },
  },
};
</script>
<style lang="less" scoped>
.page-container {
  height: 100%;
}
.tabs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 32px;
  div {
    flex: 1;
    cursor: pointer;
    &.active {
      color: #409eff;
    }
  }
}
.tree-container {
  height: calc(100vh - 145px);
  overflow-y: auto;
}
.echarts {
  position: relative;
  width: 100%;
  height: calc(100vh - 135px);
  .controls {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 10;
  }
}
.map {
  position: relative;
  width: 100%;
  height: calc(100vh - 135px);
  .controls {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 10;
  }
}
.amap {
  width: 100%;
  height: calc(100vh - 135px);
}
.content-window-card {
  position: relative;
  width: 23rem;
  padding: 0.75rem 0 0 1.25rem;
  box-shadow: none;
  bottom: 0;
  left: 0;
}

.content-window-card p {
  // height: 2rem;
}
</style>
